import StorefrontPageBase from 'oneapp/src/pages/storefrontPage';
import WidgetsMgr from '../managers/widgetsMgr';

class StorefrontPage extends StorefrontPageBase {
	init() {
		super.init();
		initMarkup.call(this);
	}
}
function initMarkup() {
	document.addEventListener('DOMContentLoaded', () => {
		WidgetsMgr.init();
	});
}

new StorefrontPage().init();
